import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'searchForm', 'table' ]
  
  initialize(){
    if (this.searchFormTarget.children[0].children[0].children[0].value != 'custom')
    {
      this.searchFormTarget.children[1].style.display = 'none'
    }
  }

  refreshDateRange(){
    
    if (this.searchFormTarget.children[0].children[0].children[0].value == 'custom')
    {
      this.searchFormTarget.children[1].style.display = 'flex'
    }
    else
    {
      this.searchFormTarget.submit()
    }
  }

  refreshDate(){
    var from = new Date(this.searchFormTarget.children[1].children[0].children[1].value)
    var to = new Date(this.searchFormTarget.children[1].children[1].children[1].value)
    if (from <= to)
    {
      this.searchFormTarget.submit()
    }
  }
}