import { data } from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'upl' ]
  
  initialize(){
    var obj = this
    obj.uplTargets.forEach(function(element) {
      $(element).find('input').on('change', function() {
        $(this.parentElement).attr('data-action', 'update');
      });
      $(element).find('select').on('change', function() {
        $(this.parentElement).attr('data-action', 'update');
      });

      $(element).find('.delete').on('click', function() {
        $(this.parentElement).attr('data-action', 'delete');
        $(this.parentElement).hide();
      });

      $(element).find('.add').on('click', function() {
        var element = $(this.parentElement)
        $.ajax({
          url: "/admin/user_pay_levels/new",
          dataType: "text",
          type: "GET",
          data: { user_id: $(this.parentElement).attr('data-user-id') },
          success: function(r) {
            $(r).insertBefore( element ).find('.delete').on('click', function() {
              $(this.parentElement).remove();
            });
          }
        });
      });
    });

    $('#save').on('click', function() {
      var data = { delete: [], update: [], create: [] }

      obj.uplTargets.forEach(function(element) {
        var e = $(element)
        var action = e.attr('data-action');

        if (action == 'delete') {
          data.delete.push( e.attr('data-uplid') );
        } else if (action == 'update') {
          data.update.push( { id: e.attr('data-uplid'), data: { from: e.find('input')[0].value, pay_level_id: e.find('select')[0].value } } );
        } else if (action == 'create') {
          data.create.push( { from: e.find('input')[0].value, pay_level_id: e.find('select')[0].value, user_id: e.attr('data-user-id') } );
        }
      });

      $.ajax({
        type: "POST",
        data: data,
        url: "/admin/user_pay_levels",
        success: function() {
          window.location.assign('/admin/user_pay_levels')
        }
      });
    });
  }
}