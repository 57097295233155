import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ ]
  
  initialize(){

    $('#trello_board_country').on('change', function() {
      if (this.value == 'Australia') {
        $('.trello_board_state')[0].style.display = 'block';
      } else {
        $('.trello_board_state')[0].style.display = 'none';
      }
    })
  }
}