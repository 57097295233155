import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'searchForm', 'card' ]

  refreshDate(){
    var from = new Date(this.searchFormTarget.children[0].children[0].children[1].value)
    var to = new Date(this.searchFormTarget.children[0].children[1].children[1].value)
    if (from <= to)
    {
      this.searchFormTarget.submit()
    }
  }

  hours = 0
  array = []
  formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })

  active(obj, active){
    var card = obj.parentElement.parentElement.parentElement.parentElement
    var project = card.parentElement.parentElement.parentElement

    if (active)
    {
      if(obj.classList.contains('active')) return
      this.array.push(parseInt(obj.children[0].id))
      this.hours += parseInt(obj.children[1].innerText.replace(/[^0-9-]+/g,""));
      obj.classList.add('active')
      card.classList.add('active')
      project.classList.add('active')
    } else {
      if(!obj.classList.contains('active')) return
      this.array.splice(this.array.indexOf(parseInt(obj.children[0].id)), 1)
      this.hours -= parseInt(obj.children[1].innerText.replace(/[^0-9-]+/g,""));
      obj.classList.remove('active')

      var count = 0
      var children = obj.parentElement.children
      var i = 0
      for(; i < children.length; i += 1){
        if (children[i].classList.contains('active')) count += 1
      }
      if (count == 0) card.classList.remove('active')

      count = 0
      children = card.parentElement.children
      for(i = 0; i < children.length; i += 1){
        if (children[i].classList.contains('active')) count += 1
      }
      if (count == 0) project.classList.remove('active')
    }
    document.getElementById('total').innerText = "Total: " + (this.hours / 10).toFixed(1) + " Hours"
  }

  init_y = 0
  init_top = 0
  init_bot = 0
  py = 0
  mousedown = false
  startdrag = false

  mouseDown(event){
    this.init_y = event.clientY
    this.py = event.clientY
    this.mousedown = true
    this.init_top = event.target.parentElement.getBoundingClientRect().top
    this.init_bot = event.target.parentElement.getBoundingClientRect().bottom
    if (!event.target.parentElement.classList.contains('active'))
    {
      this.active(event.target.parentElement, true)
    } else {
      this.active(event.target.parentElement, false)
    }
  }

  mouseUp(){
    this.mousedown = false
    this.startdrag = false
  }

  dragMove(event){
    if (!this.mousedown) return
    if (!this.startdrag && (event.clientY > this.init_bot || event.clientY < this.init_top)) {
      this.startdrag = true
    }
    if (!this.startdrag) return

    this.cardTargets.filter( (obj) => {
      if (obj.getBoundingClientRect().bottom > Math.min(this.init_y, this.py, event.clientY) && obj.getBoundingClientRect().top < Math.max(this.init_y, this.py, event.clientY)) {
        return obj
      }
    })
    .forEach( (obj) => {
      if (obj.getBoundingClientRect().bottom > Math.min(this.init_y, event.clientY) && obj.getBoundingClientRect().top < Math.max(this.init_y, event.clientY)) {
        this.active(obj, true)
      } else {
        this.active(obj, false)
      }
      
    })
    
    this.py = event.clientY
  }

  submit(){
    window.location.href = `cards_calculate/summary?from=${this.searchFormTarget.children[0].children[0].children[1].value}&to=${this.searchFormTarget.children[0].children[1].children[1].value}&ids=${JSON.stringify(this.array)}`
  }
}