import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'item' ]
  
  add(event){
    var elem = event.target.parentElement.parentElement
    elem.parentElement.append(elem.cloneNode(true))
    event.target.setAttribute('data-action', 'click->sales#remove')
    event.target.innerText = 'Remove'
  }

  remove(event){
    event.target.parentElement.parentElement.remove()
  }

  save(event){
    event.preventDefault()
    var board = document.getElementById('lead_trello_board_id').value
    var list = []

    document.getElementsByName('sale').forEach( (obj) => {
      var hash = {}
      hash['stage'] = obj.children[0].children[0].children[1].value
      hash['amount'] = obj.children[1].children[0].children[1].value
      hash['status'] = obj.children[2].children[0].children[1].value
      hash['creation_date'] = obj.children[3].children[0].children[1].value
      list.push(hash)
    } )
    console.log(list)
    $.ajax({
      url: '/admin/sales',
      type: 'POST',
      data: {
        trello_board_id: board,
        sales: JSON.stringify(list)
      },
      success: function(result) {
        if(result['suc'] == true) {
          window.location.assign(`/admin/sales?message=${result['message']}`)
        } else {
          window.location.assign(`/admin/sales/new?message=${result['message']}`)
        }
      }
    })
  }
}