import { data } from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'ur' ]
  
  initialize(){
    var obj = this
    obj.urTargets.forEach(function(element) {
      $(element).find('select').on('change', function() {
        $(this.parentElement).attr('data-action', 'update');
      });
    });

    $('#save').on('click', function() {
      var data = { update: [] }

      obj.urTargets.forEach(function(element) {
        var e = $(element)
        var action = e.attr('data-action');

        if (action == 'update') {
          data.update.push( { role_id: e.find('select')[0].value, id: e.attr('data-user-id') } );
        }
      });

      $.ajax({
        type: "POST",
        data: data,
        url: "/admin/user_roles",
        success: function() {
          window.location.assign('/admin/user_roles')
        }
      });
    });
  }
}