import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ 'input' ]
  connect() {
    this.inputTargets.forEach( (input) => {
        $(input).select2({
          tags: true
        });
    })
  }
}
